<template>
    <div class='UserInfo'>
        <el-card class="box-card">
            <div>
                <div class="clearfix">
                    个人信息修改
                </div>
            </div>

            <el-divider></el-divider>
            <div class="formbox">
                <el-form :model="userInfo" :rules="rules" ref="userInfo">
                    <el-form-item label="账号" :label-width="formLabelWidth" prop="account">
                        <el-input v-model="userInfo.account" autocomplete="off" disabled class="input_width"></el-input>
                    </el-form-item>
                    <el-form-item label="真实姓名" :label-width="formLabelWidth" prop="realName">
                        <el-input v-model="userInfo.realName" autocomplete="off" class="input_width"></el-input>
                    </el-form-item>
                    <el-form-item label="角色" :label-width="formLabelWidth">
                        <!-- <el-input v-model="formInline.account" placeholder="请输入账号查询"></el-input> -->
                        <el-select placeholder="请选择角色名称" v-model="userInfo.roleId" :disabled="dialogFormView">
                            <el-option v-for="(role, index) in roleList" :key="index" :label="role.roleName"
                                :value="role.roleId"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="联系方式" :label-width="formLabelWidth" prop="mobile">
                        <el-input v-model="userInfo.mobile" autocomplete="off" class="input_width"></el-input>
                    </el-form-item>
                    <el-form-item label="邮箱" :label-width="formLabelWidth" prop="email">
                        <el-input v-model="userInfo.email" autocomplete="off" class="input_width"></el-input>
                    </el-form-item>
                    <el-form-item label="用户状态" :label-width="formLabelWidth" prop="userStatus">
                        <el-radio v-model="userInfo.userStatus" :label=0>停用</el-radio>
                        <el-radio v-model="userInfo.userStatus" :label=1>启用</el-radio>
                    </el-form-item>
                    <el-form-item label="备注" :label-width="formLabelWidth" prop="remark">
                        <el-input v-model="userInfo.remark" autocomplete="off" class="input_width"></el-input>
                    </el-form-item>
                    <el-form-item class="df"  :label-width="formLabelWidth">
                        <el-button class="btn" @click="sureUser('userInfo')" >提交</el-button>
                    </el-form-item>
                </el-form>

            </div>

        </el-card>
    </div>
</template>

<script>
import { getToken } from '@/utils/setToken.js';
import { updateUser, queryUser } from '@/api/user'
import { queryRoleAll } from '@/api/role'
export default {
    data() {
        return {
            roleList: [],
            userInfo: {
                account: null,
                realName: null,
                roleId: null,
                mobile: null,
                email: null,
                userStatus: null,
                remark: null
            },
            formLabelWidth: "80px",
            dialogFormView: true,
            rules:{
                account: [{required: true }],
                // account: [{required: true, message: '请输入账号'}],
                realName: [{ required: true, message: '请输入姓名' }],
                roleId: [{ required: true, message: '请选择角色' }],
                userStatus: [{ required: true }],
                email: [
                    // eslint-disable-next-line no-useless-escape
                    { pattern: /^([0-9A-Za-z\-_\.]+)@([0-9a-z]+\.[a-z]{2,3}(\.[a-z]{2})?)$/g, message: '请输入正确的邮箱', trigger: 'blur' }
                ]
            }

        }
    },
    mounted() {
        this.userInfo = window.localStorage.getItem('userInfo') ? JSON.parse(getToken('userInfo')) : {};
        this.loadUserInfo(this.userInfo.account)
        this,this.loadSelect()
    },
    methods: {

        async loadSelect() {
            await queryRoleAll().then(res => {
                if (res.code === '000000') {
                    this.roleList = res.data
                }
            })
        },
        loadUserInfo(account) {
            queryUser(account).then(res => {
                if (res.code === '000000') {
                    this.userInfo = res.data
                }
            })
        },
        sureUser(userInfo) {
            this.$refs[userInfo].validate(valid => {
                if (valid) {
                    //修改
                    updateUser(this.userInfo).then(res => {
                        if (res.code === '000000') {
                            this.loadUserInfo(this.userInfo.account)
                            this.$message.success('修改成功')
                            this.$router.go(-1)
                        }
                    })
                }
            })
        },

    }
}

</script>


<style lang="scss" scoped>
.UserInfo {
    .el-card {
        position: relative;
        text-align: left;
    }

    .el-form-item {
        width: 80%;
    }

    .input_width {
        width: 400px;
    }

    .df {
        text-align: left;
        width: 500px;
    }




}
</style>